.two-column-layout {
    height: 100vh; /* Adjust the height as needed */
    position: relative;
  }
  
  .resize-handle {
    position: relative;
    top: 0;
    /* right: -5px; */
    /* left: 50%;  */
    /* Position at the border */
    /* transform: translateX(-50%);  */
    /* Adjust for centering */
    width: 10px;
    height: 100%;
    cursor: col-resize;
    background-color: #d12424;
  }
  
  /* Customize the styles for the left and right columns */
  .two-column-layout .ant-col {
    padding: 16px;
    /* background-color: #f0f0f0; */
    border: 1px solid #d9d9d9;
  }
  