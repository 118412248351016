/* Container for the gamepad */
.gamepad {
  display: grid;
  grid-template-areas:
    "l2 . r2"
    "l1 . r1"
    "dpad . abxy"
    "left-stick . right-stick";
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 1fr auto;
  gap: 10px;
  justify-items: center;
  align-items: center;
  width: 350px;
  height: 280px;
  background-color: #333;
  border-radius: 20px;
  padding: 15px;
  color: #fff;
  font-family: Arial, sans-serif;
}

/* Button styles */
.gamepad button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #666;
  color: #fff;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
}

.gamepad button:active {
  background-color: #999;
}

/* Joysticks */
.gamepad .joystick {
  /* width: 80px;
  height: 80px; */
  border-radius: 50%;
  /* background-color: #444; */
  position: relative;    
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .gamepad .joystick::before {
  content: "";
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #777;
  position: absolute;
} */

/* D-Pad */
.gamepad .dpad {
  display: grid;
  grid-template-areas:
    ". up ."
    "left . right"
    ". down .";
  gap: 5px;
}

.gamepad .dpad button {
  width: 35px;
  height: 35px;
}

.gamepad .dpad .up { grid-area: up; }
.gamepad .dpad .down { grid-area: down; }
.gamepad .dpad .left { grid-area: left; }
.gamepad .dpad .right { grid-area: right; }

/* Main buttons (A, B, X, Y) */
.gamepad .abxy {
  display: grid;
  grid-template-areas:
    ". y ."
    "x . b"
    ". a .";
  gap: 5px;
}

.gamepad .abxy button {
  width: 35px;
  height: 35px;
}

.gamepad .abxy .a { grid-area: a; background-color: #28a745; }
.gamepad .abxy .b { grid-area: b; background-color: #dc3545; }
.gamepad .abxy .x { grid-area: x; background-color: #007bff; }
.gamepad .abxy .y { grid-area: y; background-color: #ffc107; }

/* Top buttons (L1, L2, R1, R2) */
.gamepad .l1, .gamepad .l2, .gamepad .r1, .gamepad .r2 {
  width: 60px;
  height: 20px;
  border-radius: 5px;
  background-color: #555;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

/* Grid areas for gamepad */
.gamepad .left-stick { 
  grid-area: left-stick; 
  transform: 
    /* translateY(-20%)  */
    translateX(50%);
}
.gamepad .right-stick { grid-area: right-stick; 
  transform: 
    /* translateY(-20%)  */
    translateX(-50%);
}
.gamepad .dpad { grid-area: dpad; }
.gamepad .abxy { grid-area: abxy; }
.gamepad .l1 { grid-area: l1; }
.gamepad .l2 { grid-area: l2; }
.gamepad .r1 { grid-area: r1; }
.gamepad .r2 { grid-area: r2; }
