
.spot-control {  
  display: grid;
  grid-template-areas:
    "movepad cmdpad";
  grid-template-columns: auto auto;
  /* grid-template-rows: repeat(4, 1fr); */
  justify-items: center;
  align-items: center;
  row-gap: 5px;
  column-gap: 10px;
  width: 350px;
  /* height: 200px; */
  /* background-color: #333; */
  border-radius: 20px;
  padding: 0px;
  color: #fff;
  font-family: Arial, sans-serif;
}

.spot-control-buttons {
  grid-area: movepad;
  display: grid;
  grid-template-areas:
    "turnleft forward turnright"    
    "sleft stop sright"    
    ". backward ."
    "sit . stand";
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  justify-items: center;
  align-items: center;
  row-gap: 5px;
  width: 150px;
  height: 200px;
  background-color: #333;
  border-radius: 20px;
  padding: 5px;
  color: #fff;
  font-family: Arial, sans-serif;
}


.spot-command-pad {
  grid-area: cmdpad;
  display: grid;
  grid-template-areas:
    "connect start"
    "power-on pause"
    "e-stop restart"
    "walk cancel-mission";
  grid-template-columns: repeat(2, 1fr);
  /* grid-template-rows: repeat(4, 1fr); */
  justify-items: center;
  align-items: center;
  /* row-gap: 5px; */
  width: 190px;
  height: 200px;
  background-color: #333;
  border-radius: 20px;
  padding: 5px;
  color: #fff;
  font-family: Arial, sans-serif;
}

.spot-control-buttons button{  
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #666;
  color: #fff;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
}

.spot-control-buttons button:active {
  background-color: #999;
}

.spot-control-buttons button:hover {
  color: #0c0101;
}

.spot-control-buttons .sleft {
  grid-area: sleft;
  /* background-image: url('backward-arrow.png'); */
}
.spot-control-buttons .sright {
  grid-area: sright;
  /* background-image: url('backward-arrow.png'); */
}
.spot-control-buttons .sit {
  grid-area: sit;
  width: 45px;
  height: 40px;
  border-radius: 10%;
}
.spot-control-buttons .stand {
  grid-area: stand;
  width: 45px;
  height: 40px;
  border-radius: 10%;
}

.spot-control-buttons .forward {
  grid-area: forward;
  /* background-image: url('forward-arrow.png'); */
}

.spot-control-buttons .turnleft {
  grid-area: turnleft;
  /* background-image: url('turnleft-arrow.png'); */

}

.spot-control-buttons .stop {
  grid-area: stop;
  background-color: #f03e3e;
  font-weight: bold;
  width: 45px;
  height: 40px;
  border-radius: 10%;
}

.spot-control-buttons .turnright {
  grid-area: turnright;
  /* background-image: url('turnright-arrow.png'); */
}

.spot-control-buttons .backward {
  grid-area: backward;
  /* background-image: url('backward-arrow.png'); */
}

.status {
  font-weight: bold;
}

.spot-command-pad button{
  width: 80%;  
  height: 40px;
  border-radius: 10px;
  background-color: #666;
  color: #fff;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
}

.spot-command-pad button:active {
  background-color: #999;
}

.spot-command-pad button:hover {
  color: #0c0101;
}

.spot-command-pad .connect {
  grid-area: connect;
}

.spot-command-pad .start {
  grid-area: start;
}

.spot-command-pad .power-on {
  grid-area: power-on;
}

.spot-command-pad .pause {
  grid-area: pause;
}

.spot-command-pad .e-stop {
  grid-area: e-stop;
}

.spot-command-pad .restart {
  grid-area: restart;
}

.spot-command-pad .walk {
  grid-area: walk;
}

.spot-command-pad .restart {
  grid-area: restart;
}