
.robot-control {
  text-align: center;
  padding: 10px;
}

.control-buttons {
  display: grid;
  grid-template-areas:
    ". up ."
    "left stop right"
    ". down .";
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  justify-items: center;
  align-items: center;
  width: 200px;
  height: 200px;
}

.up {
  grid-area: up;
  /* background-image: url('up-arrow.png'); */
}

.left {
  grid-area: left;
  /* background-image: url('left-arrow.png'); */

}

.stop {
  grid-area: stop;
  background-color: #f03e3e;
  font-weight: bold;
}

.right {
  grid-area: right;
  /* background-image: url('right-arrow.png'); */
}

.down {
  grid-area: down;
  /* background-image: url('down-arrow.png'); */
}

button {
  margin: 0;
  padding: 0;
  border: none;
  background-repeat: no-repeat;
  background-size: contain;
  width: 50px;
  height: 50px;
}

.status {
  font-weight: bold;
}
