

.mission-command-buttons-grid {
  display: grid;
  /* grid-template-areas:
    ". forward ."
    "sleft backward sright"
    "turnleft . turnright"
    "sit stop stand"; */
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-rows: repeat(4, 1fr); */
  justify-items: center;
  align-items: center;
  row-gap: 5px;
  width: 220px;
  height: 200px;
}

.mission-command-buttons-grid button{
  border-radius: 5px;  
  width: 80%;  
  height: 50px;
}

/* .mission-command-buttons-grid .pause {
  grid-column: 1;
  grid-row: 1;
}

.mission-command-buttons-grid .resume {
  grid-column: 2;
  grid-row: 1;
}

.mission-command-buttons-grid .next-job {
  grid-column: 1;
  grid-row: 2;
}

.mission-command-buttons-grid .cancel {
  grid-column: 2;
  grid-row: 2;
} */

/* .mission-command-buttons-grid .finish {
  grid-column: 2;
  grid-row: 2;
}

.mission-command-buttons-grid .failed {
  grid-column: 2;
  grid-row: 2;
} */