 .blinking-div {
    border: 7px solid red;
    animation: blink 1s linear infinite;
  }
  
  @keyframes blink {
    0% {
      border-color: red;
    }
    50% {
      border-color: transparent;
    }
    100% {
      border-color: red;
    }
  }

  .warning-blink {
    color: red;
    animation: blinker 1s linear infinite;
  }
  
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
