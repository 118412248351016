.disabled-image {
    opacity: 0.5;
    pointer-events: none;
    filter: grayscale(100%);
    /* Add other styling as needed */
 }

 .btn-group{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
 }