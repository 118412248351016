.robot-status {
    text-align: center;
    font-size: 18px;
    /* width: 90px;
    height: 90px; */
}

.avatar-container {
    width: 60px; 
    height: 60px;
    border-radius: 50%; 
    overflow: hidden; 
    margin: 0 auto;    
}

.avatar {
    width: 100%; 
    height: 100%;
    /* object-fit: cover;  */
}

/* .robot-status.avatar {
    width: 100%; 
    height: 100%;
    border-radius: 50%; 
    object-fit: cover;
} */

.robot-name {
    margin-top: 0px;
    font-size: 18px;
    /* text-align: center; */
    font-weight: bold;
}

.status-info {
    font-size: medium;
}

.robot-speed {
    width: 45px; 
    height: 30px;
    border-radius: 50%; 
    object-fit: cover;
}

.connected {
    color: green;    
}

.disconnected {
    color: red;    
}